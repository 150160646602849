exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-environment-js": () => import("./../../../src/pages/environment.js" /* webpackChunkName: "component---src-pages-environment-js" */),
  "component---src-pages-examples-js": () => import("./../../../src/pages/examples.js" /* webpackChunkName: "component---src-pages-examples-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-overview-js": () => import("./../../../src/pages/overview.js" /* webpackChunkName: "component---src-pages-overview-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-reference-js": () => import("./../../../src/pages/reference.js" /* webpackChunkName: "component---src-pages-reference-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */),
  "component---src-templates-download-js": () => import("./../../../src/templates/download.js" /* webpackChunkName: "component---src-templates-download-js" */),
  "component---src-templates-examples-example-js": () => import("./../../../src/templates/examples/example.js" /* webpackChunkName: "component---src-templates-examples-example-js" */),
  "component---src-templates-reference-class-js": () => import("./../../../src/templates/reference/class.js" /* webpackChunkName: "component---src-templates-reference-class-js" */),
  "component---src-templates-reference-field-js": () => import("./../../../src/templates/reference/field.js" /* webpackChunkName: "component---src-templates-reference-field-js" */),
  "component---src-templates-reference-function-js": () => import("./../../../src/templates/reference/function.js" /* webpackChunkName: "component---src-templates-reference-function-js" */),
  "component---src-templates-reference-libraries-index-js": () => import("./../../../src/templates/reference/libraries/index.js" /* webpackChunkName: "component---src-templates-reference-libraries-index-js" */),
  "component---src-templates-reference-libraries-library-js": () => import("./../../../src/templates/reference/libraries/library.js" /* webpackChunkName: "component---src-templates-reference-libraries-library-js" */),
  "component---src-templates-reference-tools-js": () => import("./../../../src/templates/reference/tools.js" /* webpackChunkName: "component---src-templates-reference-tools-js" */),
  "component---src-templates-releases-js": () => import("./../../../src/templates/releases.js" /* webpackChunkName: "component---src-templates-releases-js" */),
  "component---src-templates-tutorials-tutorial-js": () => import("./../../../src/templates/tutorials/tutorial.js" /* webpackChunkName: "component---src-templates-tutorials-tutorial-js" */)
}

